import React from 'react';

interface Props {
  dados: object;
  traducao: string;
}

const Banner: React.FC<Props> = ({ dados, traducao }: Props) => {
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);

  return (
    <>
      <section id="content-3-3" className="content-3-3 content-block-nopad">
        <div className="overlay overlay-blue">
          <div className="container text-center">
            <h1>{data.titulo}</h1>
            <h2>{data.subtitulo}</h2>
            <div className="col-md-6 col-md-offset-3 text-center">
              <div className="col-md-6">
                <a
                  href={`${traducao}#contato`}
                  className="btn btn-block btn-outline btn-outline-lg outline-light"
                >
                  {data.btn1}
                </a>
              </div>
              <div className="col-md-6">
                <a
                  href={`${traducao}#negocios`}
                  className="btn btn-block btn-outline btn-outline-lg outline-light"
                >
                  {data.btn2}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
