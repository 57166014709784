import React from 'react';

import audio from '../../audio/marinheiroquefaziasal.mp3';

export interface Props {
  titulo: string;
  conteudo: string;
  url: string;
  titulourl: string;
  url2: string;
  titulourl2: string;
  url3: string;
  titulourl3: string;
  url4: string;
  titulourl4: string;
  btnFecharModal: string;
}
/**
 * Popup utilizada para abrir o conteúdo de negócio
 *
 * @param titulo
 * @param conteudo
 */
const ModalNegocio: React.FC<Props> = ({
  titulo,
  conteudo,
  url,
  titulourl,
  url2,
  titulourl2,
  url3,
  titulourl3,
  url4,
  titulourl4,
  btnFecharModal,
}: Props) => {
  return (
    <>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {titulo}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body conteudo-modal">
            <p>{conteudo}</p>
            <p>
              <a href={`${url}`} target="_blanck">
                {titulourl}
              </a>
            </p>
            <p>
              <a href={`${url2}`} target="_blanck">
                {titulourl2}
              </a>
            </p>
            <p>
              <a href={`${url3}`} target="_tab">
                {titulourl3}
              </a>
            </p>
            <span>
              {titulourl4}
              <p>
                {titulourl4 !== '' ? (
                  <audio controls title={titulourl4}>
                    <track src={titulourl4} kind={titulourl4} />
                    <source src={audio} type="audio/mp3" />
                    seu navegador não suporta HTML5
                  </audio>
                ) : (
                  ''
                )}
              </p>
            </span>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              {btnFecharModal}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalNegocio;
