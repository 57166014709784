import React from 'react';
import Isviet from '../Isviet';

interface Sobre {
  dados: object;
  traducao: string;
}
const Sobre: React.FC<Sobre> = ({ dados, traducao }: Sobre) => {
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);
  return (
    <>
      <section id="sobre" className="content-block content-1-2">
        <div className="container">
          <div className="row">
            <div className="underlined-title">
              <br />
              <h1>{data.titulo}</h1>
              <hr />
            </div>
            <div className="col-sm-6 conteudo">
              <h2>{data.subtitulo}</h2>
              <p>{data.paragrafo1}</p>
              <p>{data.paragrafo2}</p>
              <a href={`${data.urldivulgacao}`}>
                <p className="divulgacao-quem-somos">{data.divulgacao}</p>
              </a>
              <div className="row">
                <div className="col-sm-5 col-xs-12">
                  <a
                    href={`${traducao}#negocios`}
                    className="btn btn-block btn-primary"
                  >
                    {data.btn1}
                  </a>
                </div>
                <div className="col-sm-5 col-xs-12">
                  <a
                    href={`${traducao}#contato`}
                    className="btn btn-block btn-warning"
                  >
                    {data.btn2}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-sm-offset-0">
              <div className="thumbnail">
                <iframe
                  className="video"
                  src={data.urlvideo}
                  title={data.titulovideo}
                />
                <div className="caption">
                  <h3>{data.subtitulovideo}</h3>
                </div>
              </div>
            </div>
            <Isviet acao="feliz" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Sobre;
