import React from 'react';

interface Props {
  dados: object;
}

const Metodos: React.FC<Props> = ({ dados }: Props) => {
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);
  return (
    <>
      <section className="content-block contact-1" id="metodologia">
        <div className="container text-center">
          <div className="col-sm-10 col-sm-offset-1">
            <div className="underlined-title">
              <h1>{data.titulo}</h1>
              <hr />
              <h2>{data.subtitulo}</h2>
            </div>

            <ul className="texto-metodologia">
              <p className="subtitulo-2">{data.subtitulo2}</p>
              <li>
                <p>{data.item1}</p>
                <p>{data.item2}</p>
                <p>{data.item3}</p>
                <p>{data.item4}</p>
                <p>{data.item5}</p>
                <p>{data.item6}</p>
                <p>{data.item7}</p>
                <p>{data.item8}</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Metodos;
