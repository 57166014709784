import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import Home from '../pages/Home';
import Privacidade from '../pages/Privacidade';

import * as IdiomaPt from '../data/pt/conteudo.json';
import * as IdiomaEng from '../data/en/conteudo.json';
import * as IdiomaEsp from '../data/sp/conteudo.json';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/pt" />} />
        <Route path="/pt" component={() => <Home data={IdiomaPt} />} />
        <Route path="/en" component={() => <Home data={IdiomaEng} />} />
        <Route path="/es" component={() => <Home data={IdiomaEsp} />} />
        <Route path="/politicas-de-privacidade" component={Privacidade} />
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;
