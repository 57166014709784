import React, { useState, useMemo } from 'react';

interface Props {
  dados: object;
}

interface Noticia {
  titulosessao: string;
  listanoticia: Array<NoticiaItem>;
}

interface NoticiaItem {
  id: string;
  titulo: string;
  conteudo: string;
  urlvideo: string;
}

/**
 * Componente para responsavel pela sessão de noticias
 *
 * @param dados
 */
const Noticias: React.FC<Props> = ({ dados }: Props) => {
  const [noticia, setNoticia] = useState<Noticia>();
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);

  useMemo(async () => {
    setNoticia(data);
  }, []);

  return (
    <>
      <section id="noticias" className="news-1 content-block">
        <div className="container">
          <div className="row">
            <div className="underlined-title">
              <br />
              <h1>{noticia?.titulosessao}</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            {noticia?.listanoticia.map((item) => (
              <div
                key={item.id}
                className="col-sm-4 col-md-4"
                data-mh="group-news"
              >
                <div className="thumbnail">
                  <iframe
                    className="video-noticia"
                    src={item.urlvideo}
                    title={item.titulo}
                  />
                  <div className="caption">
                    <h3>{item.titulo}</h3>
                    <p className="conteudo-noticia">{item.conteudo}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Noticias;
