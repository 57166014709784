import React from 'react';
import CookieConsent from 'react-cookie-consent';

import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Sobre from '../../components/Sobre';
import Historia from '../../components/Historia';
import Noticias from '../../components/Noticias';
import Banner from '../../components/Banner';
import Negocios from '../../components/Negocios';
import Parceiros from '../../components/Parceiros';
import Contatos from '../../components/Contatos';
import Footer from '../../components/Footer';
import Topo from '../../components/Topo';
import Metodologia from '../../components/Metodos';

interface Props {
  data: object;
}

const Home: React.FC<Props> = ({ data }: Props) => {
  const a = JSON.stringify(data, null, '');
  const b = JSON.parse(a);
  const dadoscookie = b.default.cookies;

  return (
    <>
      <Topo />
      <Menu dadosMenu={b.default.menu} />
      <section id="content-1-9" className="content-1-9 content-block" />
      <Header dados={b.default.home} />
      <Sobre dados={b.default.quemsomos} traducao={b.default.traducao} />
      <Historia dados={b.default.historia} />
      <Banner dados={b.default.banner} traducao={b.default.traducao} />
      <Noticias dados={b.default.noticias} />
      <Negocios dados={b.default.negocios} />
      <Metodologia dados={b.default.metodologia} />
      <Parceiros dados={b.default.parceiros} />
      <Contatos dados={b.default.contato} traducao={b.default.traducao} />
      <section />
      <Footer dados={b.default.rodape} traducao={b.default.traducao} />

      <div className="cookie-lia">
        <CookieConsent
          location="top"
          expires={Number(dadoscookie.expires)}
          cookieName={dadoscookie.nomecookie}
          buttonText={`${dadoscookie.buttonText}`}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        >
          <span className="texto-cookie">{dadoscookie.conteudo}</span>
          <span className="subtitulo-cookie">{dadoscookie.subconteudo}</span>
        </CookieConsent>
      </div>
    </>
  );
};

export default Home;
