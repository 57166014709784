import React from 'react';

import apontando from '../../images/Apontando.png';
import chamando from '../../images/Chamando.png';
import feliz from '../../images/FelizInvertida.png';
import voando from '../../images/VoandoInvertida.png';
import './index.css';

interface Props {
  acao: string;
}

const Isviet: React.FC<Props> = ({ acao }: Props) => {
  return (
    <div className="divisviet">
      <img
        src={
          acao === 'apontando'
            ? apontando
            : acao === 'feliz'
            ? feliz
            : acao === 'voando'
            ? voando
            : acao === 'chamando'
            ? chamando
            : ''
        }
        className={`isviet-${acao}`}
        alt=""
      />
    </div>
  );
};

export default Isviet;
