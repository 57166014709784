import React from 'react';

const Politica: React.FC = () => {
  return (
    <>
      <section className="content-block contact-1" id="politica-privacidade">
        <div className="container text-center">
          <div className="col-sm-10 col-sm-offset-1">
            <div className="underlined-title">
              <br />
              <h1>Política de Privacidade</h1>
              <hr />
            </div>
            <div className="col-sm-12 conteudo">
              <p className="titulo">A. Introdução</p>
              <p>
                1. A privacidade dos visitantes do nosso site é muito importante
                para nós, e estamos comprometidos em protegê-la. Esta política
                explica o que faremos com suas informações pessoais.
              </p>
              <p>
                2. Consentir com o uso de cookies de acordo com os termos desta
                política quando você acessa nosso site pela primeira vez nos
                permite usar cookies toda vez que você acessa nosso site.
              </p>
              <p className="titulo">B. Crédito</p>
              <p>
                Este documento foi criado usando um modelo da SEQ Legal
                (seqlegal.com) modificado pelo Website Planet
                (www.websiteplanet.com) e pela empresa LOGISTICS INTERNATIONAL
                ASSOCIATES - LIA (www.lia-inc.com)
              </p>
              <p className="titulo">C. Coleta de informações pessoais</p>
              <p>
                Os seguintes tipos de informações pessoais podem ser coletados,
                armazenados e usados:
              </p>
              <p>
                1. informações sobre o seu computador, incluindo seu endereço
                IP, localização geográfica, tipo e versão do navegador e sistema
                operacional;
              </p>
              <p>
                2. informações sobre suas visitas e uso deste site, incluindo
                fonte de referência, duração da visita, visualizações de página
                e caminhos de navegação no site;
              </p>
              <p>
                3. informações, como seu endereço de e-mail, que você digita
                quando se registra em nosso site;
              </p>
              <p>
                4. informações que você digita ao criar um perfil em nosso site
                – por exemplo, seu nome, fotos de perfil, sexo, data de
                nascimento, status de relacionamento, interesses e hobbies,
                informações educacionais e de emprego;
              </p>
              <p>
                5. informações, como seu nome e endereço de e-mail, que você
                digita para configurar assinaturas de nossos e-mails e/ou
                newsletters;
              </p>
              <p>
                6. informações que você digita durante o uso dos serviços em
                nosso site;
              </p>
              <p>
                7. informações geradas ao usar nosso site, incluindo quando, com
                que frequência e em que circunstâncias você o utiliza;
              </p>
              <p>
                8. informações relacionadas a tudo o que você compra, serviços
                que usa ou transações que realiza através do nosso site,
                incluindo nome, endereço, número de telefone, endereço de e-mail
                e dados do cartão de crédito;
              </p>
              <p>
                9. informações que você publica em nosso site com a intenção de
                publicá-las na internet, incluindo seu nome de usuário, fotos de
                perfil e o conteúdo de suas publicações;
              </p>
              <p>
                10. informações contidas em quaisquer comunicações que você nos
                envia por e-mail ou através de nosso site, incluindo o conteúdo
                e os metadados da comunicação;
              </p>
              <p>11. qualquer outra informação pessoal que você nos enviar.</p>
              <p>
                Antes de nos divulgar informações pessoais de outra pessoa, você
                deve obter o consentimento dessa pessoa para a divulgação e o
                processamento dessas informações pessoais de acordo com esta
                política.
              </p>
              <p className="titulo">D. Uso de suas informações pessoais</p>
              <p>
                As informações pessoais que nos são enviadas por meio de nosso
                site serão usadas para os fins especificados nesta política ou
                nas páginas relevantes do site. Podemos usar suas informações
                pessoais para o seguinte:
              </p>
              <p>1. administrar nosso site e nossos negócios;</p>
              <p>2. personalizar nosso site para você;</p>
              <p>
                3. possibilitar o uso dos serviços disponíveis em nosso site;
              </p>
              <p>4. enviar produtos adquiridos através do nosso site;</p>
              <p>5. prestar serviços adquiridos através do nosso site;</p>
              <p>
                6. enviar extratos, faturas e lembretes de pagamento, bem como
                coletar seus pagamentos;
              </p>
              <p>
                7. enviar comunicações comerciais que não sejam de marketing;
              </p>
              <p>
                8. enviar notificações por e-mail solicitadas especificamente
                por você;
              </p>
              <p>
                9. enviar nossa newsletter por e-mail, caso você a tenha
                solicitado (você pode nos informar a qualquer momento se não
                quiser mais receber a newsletter);
              </p>
              <p>
                10. enviar comunicações de marketing relacionadas aos nossos
                negócios ou aos negócios de terceiros cuidadosamente
                selecionados que acreditamos ser do seu interesse, por correio
                ou, onde você especificamente concordou com isso, por e-mail ou
                tecnologia semelhante (você pode nos informar a qualquer momento
                se não mais quiser mais receber comunicações de marketing);
              </p>
              <p>
                11. fornecer a terceiros informações estatísticas sobre nossos
                usuários (mas esses terceiros não poderão identificar nenhum
                usuário individual a partir dessas informações);
              </p>
              <p>
                12. lidar com perguntas e reclamações feitas por você ou sobre
                você em relação ao nosso site;
              </p>
              <p>13. manter nosso site seguro e evitar fraudes;</p>
              <p>
                14. verificar a conformidade com os termos e condições que regem
                o uso do nosso site (incluindo o monitoramento de mensagens
                privadas enviadas por meio do serviço de mensagens privadas do
                nosso site); e
              </p>
              <p>15. outros usos.</p>
              <p>
                Se você enviar informações pessoais para publicação em nosso
                site, publicaremos e usaremos essas informações de acordo com a
                licença que você nos concedeu.
              </p>
              <p>
                Suas configurações de privacidade podem ser usadas para limitar
                a publicação de suas informações em nosso site e ajustadas
                através do uso de controles de privacidade no site.
              </p>
              <p>
                Sem seu consentimento expresso, não forneceremos suas
                informações pessoais a terceiros para fins de marketing direto
                por parte deles ou de terceiros.
              </p>
              <p className="titulo">E. Divulgação de informações pessoais</p>
              <p>
                Podemos divulgar suas informações pessoais a qualquer um de
                nossos funcionários, executivos, seguradoras, consultores
                profissionais, agentes, fornecedores ou subcontratados conforme
                razoavelmente necessário para os fins estabelecidos nesta
                política.
              </p>
              <p>
                Podemos divulgar suas informações pessoais a qualquer membro de
                nosso grupo de empresas (isso significa nossas subsidiárias,
                nossa holding e todas as suas subsidiárias) conforme
                razoavelmente necessário para os fins estabelecidos nesta
                política.
              </p>
              <p>Podemos divulgar suas informações pessoais:</p>
              <p>1. na medida em que somos obrigados a fazê-lo por lei;</p>
              <p>
                2. em relação a qualquer processo judicial em andamento ou
                potencial;
              </p>
              <p>
                3. para estabelecer, exercer ou defender nossos direitos legais
                (incluindo fornecer informações a terceiros para fins de
                prevenção de fraudes e redução do risco de crédito);
              </p>
              <p>
                4. ao comprador (ou comprador em potencial) de qualquer negócio
                ou ativo que estejamos vendendo (ou contemplando vender); e
              </p>
              <p>
                5. a qualquer pessoa que acreditemos razoavelmente que possa
                solicitar a um tribunal ou outra autoridade competente a
                divulgação dessas informações pessoais, quando, em nossa opinião
                razoável, for provável que tal tribunal ou autoridade ordene a
                divulgação dessas informações pessoais.
              </p>
              <p>
                Exceto conforme estabelecido nesta política, não forneceremos
                suas informações pessoais a terceiros.
              </p>
              <p className="titulo">
                F. Transferências internacionais de dados
              </p>
              <p>
                1. As informações que coletamos podem ser armazenadas,
                processadas e transferidas entre qualquer um dos países em que
                operamos, a fim de nos permitir usar as informações de acordo
                com esta política.
              </p>
              <p>
                2. As informações que coletamos podem ser transferidas para os
                seguintes países que não possuem leis de proteção de dados
                equivalentes às vigentes no Espaço Econômico Europeu: Estados
                Unidos da América, Rússia, Japão, China e Índia.
              </p>
              <p>
                3. As informações pessoais que você publica em nosso site ou
                envia para publicação em nosso site podem estar disponíveis,
                através da internet, em todo o mundo. Não podemos impedir o uso
                ou uso indevido de tais informações por terceiros.
              </p>
              <p>
                4. Você concorda expressamente com as transferências de
                informações pessoais descritas nesta seção F.
              </p>
              <p className="titulo">G. Retenção de informações pessoais</p>
              <p>
                1. Esta seção G define nossas políticas e procedimentos de
                retenção de dados, projetados para ajudar a garantir o
                cumprimento de nossas obrigações legais em relação à retenção e
                exclusão de informações pessoais.
              </p>
              <p>
                2. As informações pessoais que processamos para qualquer
                propósito ou propósitos não devem ser mantidas por mais tempo do
                que o necessário para esse propósito ou propósitos.
              </p>
              <p>
                3. Não obstante as outras disposições desta seção G, reteremos
                documentos (incluindo documentos eletrônicos) que contenham
                dados pessoais:
              </p>
              <p>a. na medida em que somos obrigados a fazê-lo por lei;</p>
              <p>
                b. se acreditarmos que os documentos podem ser relevantes para
                qualquer processo judicial em andamento ou potencial; e
              </p>
              <p>
                c. para estabelecer, exercer ou defender nossos direitos legais
                (incluindo fornecer informações a terceiros para fins de
                prevenção de fraudes e redução do risco de crédito).
              </p>
              <p className="titulo">
                H. Segurança de suas informações pessoais
              </p>
              <p>
                1. Tomaremos as devidas precauções técnicas e organizacionais
                para evitar a perda, mau uso ou alteração de suas informações
                pessoais.
              </p>
              <p>
                2. Armazenaremos todas as suas informações pessoais fornecidas
                em nossos servidores seguros (protegidos por senha e firewall).
              </p>
              <p>
                3. Todas as transações financeiras eletrônicas realizadas
                através do nosso site serão protegidas por tecnologia de
                criptografia.
              </p>
              <p>
                4. Você reconhece que a transmissão de informações pela internet
                é inerentemente insegura e que não podemos garantir a segurança
                dos dados enviados pela internet.
              </p>
              <p>
                5. Você é responsável por manter em sigilo a senha usada para
                acessar nosso site; não solicitaremos sua senha (exceto quando
                você fizer login em nosso site).
              </p>
              <p className="titulo">I. Alterações</p>
              <p>
                Podemos atualizar esta política periodicamente, através da
                publicação de uma nova versão em nosso site. Você deve verificar
                esta página ocasionalmente para garantir que compreende
                quaisquer alterações nesta política. Podemos notificá-lo sobre
                alterações nesta política por e-mail ou através do sistema de
                mensagens privadas em nosso site.
              </p>
              <p className="titulo">J. Seus direitos</p>
              <p>
                Você pode nos instruir a fornecer qualquer informação pessoal
                que detenhamos sobre você; o fornecimento dessas informações
                estará sujeito ao seguinte:
              </p>
              <p>1. pagamento de uma taxa; e</p>
              <p>
                2. fornecimento de evidência apropriada de sua identidade para
                esse objetivo; geralmente aceitaremos uma cópia autenticada do
                seu passaporte (ou documento oficial) acrescida de uma cópia
                original de uma fatura de serviço mostrando seu endereço atual.
              </p>
              <p>
                Podemos reter as informações pessoais solicitadas na extensão
                permitida por lei.
              </p>
              <p>
                Você pode nos instruir a qualquer momento para não processar
                suas informações pessoais para fins de marketing.
              </p>
              <p>
                Na prática, você geralmente concordará expressamente com
                antecedência com o uso de suas informações pessoais para fins de
                marketing, ou ofereceremos a oportunidade de não permitir o uso
                de suas informações pessoais para fins de marketing.
              </p>
              <p className="titulo">K. Sites de terceiros</p>
              <p>
                Nosso site inclui links para e detalhes de sites de terceiros.
                Não temos controle sobre e não somos responsáveis pelas
                políticas e práticas de privacidade de terceiros.
              </p>
              <p className="titulo">L. Atualização de informações</p>
              <p>
                Informe-nos se as informações pessoais que mantemos sobre você
                precisam ser corrigidas ou atualizadas.
              </p>
              <p className="titulo">M. Cookies</p>
              <p>
                Nosso site usa cookies. Um cookie é um arquivo que contém um
                identificador (uma sequência de letras e números) que é enviado
                por um servidor da web para um navegador e armazenado pelo
                navegador. O identificador é então enviado de volta ao servidor
                toda vez que o navegador solicita uma página do servidor. Os
                cookies podem ser cookies “persistentes” ou cookies “de sessão”:
                um cookie persistente será armazenado por um navegador e
                permanecerá válido até a data de vencimento definida, a menos
                que seja excluído pelo usuário antes da data de vencimento; um
                cookie de sessão, por outro lado, expirará no final da sessão do
                usuário, quando o navegador for fechado. Os cookies normalmente
                não contêm nenhuma informação que identifique pessoalmente um
                usuário, mas as informações pessoais que armazenamos sobre você
                podem estar vinculadas às informações armazenadas e obtidas a
                partir de cookies.
              </p>
              <p>
                1. Os nomes dos cookies que usamos em nosso site e os propósitos
                para os quais são usados estão descritos abaixo:
              </p>
              <p>
                a. usamos o Google Analytics e o Adwords em nosso site para
                reconhecer um computador quando um usuário acessa o site /
                rastrear usuários enquanto eles navegam no site / permitir o uso
                de um carrinho de compras no site / melhorar a usabilidade do
                site / analisar o uso do site / administrar o site / evitar
                fraudes e melhorar a segurança do site / personalizar o site
                para cada usuário / segmentar anúncios que podem ser de
                interesse particular para usuários específicos / descrever
                objetivo(s);
              </p>
              <p>
                2. A maioria dos navegadores permite que você se recuse a
                aceitar cookies – por exemplo:
              </p>
              <p>
                a. no Internet Explorer (versão 10), você pode bloquear cookies
                usando as configurações disponíveis de cancelamento de
                manipulação de cookies clicando em “Ferramentas”, “Opções da
                Internet” “Privacidade” e “Avançado”;
              </p>
              <p>
                b. no Firefox (versão 24), você pode bloquear todos os cookies
                clicando em “Ferramentas”, “Opções”, “Privacidade”, selecionando
                “Usar configurações personalizadas para o histórico” no menu
                suspenso e desmarcando “Aceitar cookies de sites” ; e
              </p>
              <p>
                c. no Chrome (versão 29), você pode bloquear todos os cookies
                acessando o menu “Personalizar e controlar” e clicando em
                “Configurações”, “Avançado” e “Configurações do site” e, em
                seguida, selecionando “Bloquear cookies de terceiros” na seção
                “Cookies e dados do site”.
              </p>
              <p>
                O bloqueio de todos os cookies causará um impacto negativo na
                usabilidade de muitos sites. Se você bloquear os cookies, não
                poderá usar todos os recursos em nosso site.
              </p>
              <p>
                3. Você pode excluir os cookies que já estão armazenados no seu
                computador – por exemplo:
              </p>
              <p>
                a. no Internet Explorer (versão 10), você deve excluir os
                arquivos de cookies manualmente (confira instruções para fazê-lo
                em http://support.microsoft.com/kb/278835 );
              </p>
              <p>
                b. no Firefox (versão 24), você pode excluir os cookies clicando
                em “Ferramentas”, “Opções”, “Privacidade”, selecionando “Usar
                configurações personalizadas para o histórico”, clicando em
                “Mostrar cookies” e, então, em “Remover todos os cookies”; e
              </p>
              <p>
                c. no Chrome (versão 29), você pode excluir todos os cookies
                acessando o menu “Personalizar e controlar” e clicando em
                “Configurações”, “Avançado”, “Limpar dados de navegação” e, em
                seguida, selecionando “Excluir cookies e outros dados de sites e
                plugins” antes de clicar em “Limpar dados de navegação”.
              </p>
              <p>
                4. A exclusão de cookies causará um impacto negativo na
                usabilidade de muitos sites.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Politica;
