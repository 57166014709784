import React from 'react';

import logoINPI from '../../images/Logo-INPI.png';
import Isviet from '../Isviet';

interface Props {
  dados: object;
}

const Historia: React.FC<Props> = ({ dados }: Props) => {
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);
  return (
    <>
      <section id="historia" className="content-block content-1-2">
        <div className="container">
          <div className="row">
            <div className="underlined-title">
              <br />
              <h1>{data.title}</h1>
              <hr />
            </div>
            <div className="col-sm-8">
              <h2>{data.subtitulo1}</h2>
              <p className="lead">{data.subtitulo2}</p>
              <p className="conteudo">{data.conteudo}</p>
            </div>
            <div className="col-sm-4 col-sm-offset-0">
              <img className="img-responsive" src={logoINPI} alt="logistics" />
            </div>
            <Isviet acao="voando" />
          </div>
        </div>
      </section>
      <br />
    </>
  );
};

export default Historia;
