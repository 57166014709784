import React from 'react';

import Politica from '../../components/Politica';

const Privacidade: React.FC = () => {
  return (
    <>
      <Politica />
    </>
  );
};

export default Privacidade;
