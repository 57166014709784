import React from 'react';

import './bootstrap/css/bootstrap.css';
import './css/font-awesome.min.css';
import './css/style-library-1.css';
import './css/plugins.css';
import './css/blocks.css';
import './css/custom.css';

import Routes from './routes';

const App: React.FC = () => {
  return (
    <>
      <Routes />
    </>
  );
};

export default App;
