import React from 'react';

import logo from '../../images/Logo-INPI.png';
import logoWhatsapp from '../../images/Whatsapp.png';
import logoamazon from '../../images/partner-logos/amazon.png';

interface Props {
  dados: object;
  traducao: string;
}

const Footer: React.FC<Props> = ({ dados, traducao }: Props) => {
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);

  return (
    <>
      <section
        className="bg-deepocean content-block-nopad footer-wrap-1-1"
        id="rodape"
      >
        <div className="container footer-1-1">
          <div className="row">
            <div className="col-sm-5">
              <img src={logo} className="brand-img img-responsive" alt="" />
              <h3>{data.empresa}</h3>
              <p className="lead">{data.desc_empresa}</p>
              <a
                href="https://api.whatsapp.com/send?phone=5561984509718"
                target="_tab"
              >
                <i className="fa fa-2x fa-whatsapp fa-inverse" />
              </a>
              <a href="tel:+55-61-98450-9718" className="whatsapp">
                +55 (61) 98450 9718
              </a>
            </div>
            <div className="col-sm-6 col-sm-offset-1">
              <div className="row">
                <div className="col-md-4">
                  <h4>{data.atalhos}</h4>
                  <ul>
                    <li>
                      <a href={`${traducao}#sobre`}>{data.sobre_nos}</a>
                    </li>
                    <li>
                      <a
                        href="https://cyberlia.blogspot.com"
                        target="_tab"
                        rel="noopener"
                      >
                        {data.blog}
                        {'   '}
                        <i className="fa fa-2x fa-blog fa-inverse" />
                      </a>
                    </li>
                    <li>
                      <a href={`${traducao}#contato`}>{data.contato}</a>
                    </li>
                    <li>
                      <a
                        href="http://lia-inc.com:2095/"
                        target="_black"
                        data-toggle="tooltip"
                        title="Webmail"
                      >
                        Webmail
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.amazon.com/dp/B088X497SX"
                        target="_tab"
                      >
                        <img
                          src={logoamazon}
                          width="100px"
                          height="40px"
                          alt="amazon"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h4>{data.legalStuff}</h4>
                  <ul>
                    <li>
                      <a href="/politicas-de-privacidade" target="_tab">
                        {data.privacidade}
                      </a>
                    </li>
                    {/*
                    <li>
                      <a href="/termo-de-uso" target="_tab">
                        {data.termo}
                      </a>
                    </li>
                    */}
                  </ul>
                </div>
                <div className="col-md-4">
                  <h4>Siga-Nos</h4>
                  <a
                    href="https://facebook.com/marcelo.felippes"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-facebook-f fa-inverse" />
                  </a>
                  <a
                    href="https://twitter.com/lia_inc"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-inverse fa-twitter" />
                  </a>
                  <a
                    href="https://instagram.com/marcelo.felippes?igshid=mvx3ylc9n9f1"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-instagram fa-inverse" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/marcelo-felippes-9b86934/"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-linkedin fa-inverse" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCFK3VguBzUW2EN9fbJ0zLVg"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-youtube fa-inverse" />
                  </a>
                  <a
                    href="https://www.tumblr.com/blog/lia-cyberlia"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-tumblr fa-inverse" />
                  </a>
                  <a
                    href="https://www.reddit.com/user/LIA-CYBERLIA"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-reddit fa-inverse" />
                  </a>
                  <a
                    href="https://vk.com/id593958152"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-vk fa-inverse" />
                  </a>
                  <a
                    href="https://ok.ru/profile/580649038566"
                    className="social-links"
                    target="_tab"
                  >
                    <i className="fa fa-odnoklassniki fa-inverse" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright-bar bg-black">
        <div className="container">
          <p className="pull-left small">
            Copyright ©2020 LIA - Logistics International Associates
          </p>
          <p className="pull-right small">
            Made with
            <i className="fa fa-heart pomegranate" />
            <span>by Dantas Tecnologia</span>
          </p>
        </div>
        <div className="whatsapp-fixo">
          <a
            href="https://api.whatsapp.com/send?phone=5561984509718"
            target="_tab"
          >
            <img
              src={logoWhatsapp}
              width="60px"
              height="60px"
              alt="Fale Conosco pelo WhatsApp"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
