import React from 'react';

const Topo: React.FC = () => {
  return (
    <>
      <section id="topo" className="content-block content-1-2">
        <div className="container">
          <div className="row">
            <div className="hidden-xs col-md-4 col-xs-3 col-sm-4 redes-sociais">
              <a
                href="https://facebook.com/marcelo.felippes"
                className="social-links"
                target="_tab"
              >
                <i className="fa fa-facebook-f fa-inverse" />
              </a>
              <a
                href="https://twitter.com/lia_inc"
                className="social-links"
                target="_tab"
              >
                <i className="fa fa-inverse fa-twitter" />
              </a>
              <a
                href="https://instagram.com/marcelo.felippes?igshid=mvx3ylc9n9f1"
                className="social-links"
                target="_tab"
              >
                <i className="fa fa-instagram fa-inverse" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCFK3VguBzUW2EN9fbJ0zLVg"
                className="social-links"
                target="_tab"
              >
                <i className="fa fa-youtube fa-inverse" />
              </a>
            </div>
            <div className="col-md-3 col-xs-12 col-sm-3 bandeiras">
              <span>
                <a href="/pt">
                  <span className="portugues">PORTUGUÊS</span>
                </a>
              </span>
              <span>
                <a href="/en">
                  <span className="english">ENGLISH</span>
                </a>
              </span>
              <span>
                <a href="/es">
                  <span className="spanol">ESPAÑOL</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Topo;
