import React from 'react';

import parsseiro3 from '../../images/partner-logos/xmobots.png';
import parsseiro4 from '../../images/partner-logos/apex-brasil.png';
import parsseiro5 from '../../images/partner-logos/transportes-bertoline.png';
import parsseiro6 from '../../images/partner-logos/gtax.png';
import brandMobs2 from '../../images/partner-logos/brand-mobs2.png';
import logoCit from '../../images/partner-logos/logo_cit.png';
import logoAirShipBrasil from '../../images/partner-logos/logoAirShipBrasil.png';
import logoGFCC from '../../images/partner-logos/gfcc.png';
import logoMEI from '../../images/partner-logos/mei.png';
import logoConnectamericas from '../../images/partner-logos/connectamericas.svg';
import logoCelogs from '../../images/partner-logos/celogs.png';
import logoCk3 from '../../images/partner-logos/ck3.png';
import logoMagazineLuiza from '../../images/partner-logos/magazine-luiza.png';
import logoUniversidadeMiami from '../../images/partner-logos/miami.png';
import logoGeorgetown from '../../images/partner-logos/georgetown.png';
import logoElsevier from '../../images/partner-logos/elsevier.png';
import logoSebrae from '../../images/partner-logos/sebrae.png';
import logoGoogleEducation from '../../images/partner-logos/google-logo.png';
import pdfLogisticaSubsitencia from '../../doc/CI 10 7_1_Logistica de Subsitencia.pdf';

interface Props {
  dados: object;
}

const Parceiros: React.FC<Props> = ({ dados }: Props) => {
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);
  return (
    <>
      <section id="parceiros" className="content-1-7 content-block">
        <div className="container">
          <div className="row">
            <div className="underlined-title">
              <br />
              <h1>{data.titulo}</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-4">
              <a href="http://www.citamericas.org/" target="_tab">
                <img alt="Câmara Interamericana de Transportes" src={logoCit} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="http://www.adb.ind.br/index.jsp" target="_tab">
                <img alt="Airship do Brasil" src={logoAirShipBrasil} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://xmobots.com.br/" target="_tab">
                <img alt="XMobots" src={parsseiro3} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://portal.apexbrasil.com.br/" target="_tab">
                <img alt="apexbrasil" src={parsseiro4} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://www.tbl.com.br/" target="_tab">
                <img alt="Transportes Bertolini" src={parsseiro5} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://www.gtaxsp.com.br/" target="_tab">
                <img alt="Gtax Consultoria para PME" src={parsseiro6} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="http://www.mobs2.com/" target="_tab">
                <img alt="Mobs2" src={brandMobs2} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://www.thegfcc.org/" target="_tab">
                <img
                  alt="Global Federation of Competitiveness Councils"
                  src={logoGFCC}
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a
                href="http://www.portaldaindustria.com.br/cni/canais/mei/"
                target="_tab"
              >
                <img
                  alt="Mobilização Empresarial pela Inovação"
                  src={logoMEI}
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://connectamericas.com/pt/dashboard" target="_tab">
                <img alt="Client Logo" src={logoConnectamericas} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href={pdfLogisticaSubsitencia} target="_tab">
                <img alt="Client Logo" src={logoCelogs} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a
                href="https://www.linkedin.com/company/ck3-consultoria-e-participa%C3%A7%C3%B5es/about/"
                target="_tab"
              >
                <img alt="CK3 - Consultoria e Participações" src={logoCk3} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a
                href="https://www.magazinevoce.com.br/magazinecinamericas/"
                target="_tab"
              >
                <img alt="Magazine Cinamericas" src={logoMagazineLuiza} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://welcome.miami.edu/" target="_tab">
                <img alt="Universidade de Miami" src={logoUniversidadeMiami} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://www.georgetown.edu/" target="_tab">
                <img alt="Georgetown University" src={logoGeorgetown} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://www.elsevier.com/ " target="_tab">
                <img alt="Elsevier" src={logoElsevier} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a
                href="https://www.sebrae.com.br/sites/PortalSebrae "
                target="_tab"
              >
                <img alt="Portal Sebrae" src={logoSebrae} />
              </a>
            </div>
            <div className="col-md-2 col-sm-4">
              <a href="https://edu.google.com/ " target="_tab">
                <img alt="Google for Education" src={logoGoogleEducation} />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Parceiros;
