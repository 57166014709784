import React, { useState, useMemo } from 'react';

import ModalNegocio from '../Modal';
import Isviet from '../Isviet';

interface Props {
  dados: object;
}

interface Negocio {
  titulosessao: string;
  subtitulosessao1: string;
  subtitulosessao2: string;
  btnFecharModal: string;
  listanegocio: Array<NegocioItem>;
}

interface NegocioItem {
  idNegocio: number;
  titulonegocio: string;
  conteudonegocio: string;
  url: string;
  titulourl: string;
  url2: string;
  titulourl2: string;
  url3: string;
  titulourl3: string;
  url4: string;
  titulourl4: string;
}

/**
 * Class da seção de Negócio
 */
const Negocios: React.FC<Props> = ({ dados }: Props) => {
  const [negocio, setNegocio] = useState<Negocio>();
  const [negocioiTem, setNegocioItem] = useState<NegocioItem>();
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);
  const num = -1;

  useMemo(async () => {
    setNegocio(data);
  }, []);

  /**
   * Metodo para abrir Popup de detalhamento de Negócio
   *
   * @param value1 - Recebe o item do negocio
   */

  function loadNegocio(value1: NegocioItem): void {
    const neg = value1;
    setNegocioItem({
      idNegocio: neg.idNegocio,
      titulonegocio: neg.titulonegocio,
      conteudonegocio: neg.conteudonegocio,
      url: neg.url,
      titulourl: neg.titulourl,
      url2: neg.url2,
      titulourl2: neg.titulourl2,
      url3: neg.url3,
      titulourl3: neg.titulourl3,
      url4: neg.url4,
      titulourl4: neg.titulourl4,
    });
  }

  return (
    <>
      <section id="negocios" className="content-1-9 content-block">
        <div className="container">
          <div className="underlined-title">
            <br />
            <h1>{data.titulosessao}</h1>
            <hr />
            <h2>{data.subtitulosessao1}</h2>
            <h2>{data.subtitulosessao2}</h2>
            <Isviet acao="chamando"/>
          </div>
          <div className="row lista-de-negocios">
            {negocio?.listanegocio.map((negocioiTem) => (
              <div
                key={negocioiTem.idNegocio}
                className="col-md-6 col-sm-12 col-xs-12 botoes-negocio"
              >
                <a
                  type="button"
                  onClick={() => loadNegocio(negocioiTem)}
                  href="#!"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  className="btn btn-block btn-outline outline-light btn-negocios"
                >
                  {negocioiTem?.titulonegocio}
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex={num}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <ModalNegocio
          titulo={`${negocioiTem?.titulonegocio}`}
          conteudo={`${negocioiTem?.conteudonegocio}`}
          url={`${negocioiTem?.url}`}
          titulourl={`${negocioiTem?.titulourl}`}
          url2={`${negocioiTem?.url2}`}
          titulourl2={`${negocioiTem?.titulourl2}`}
          url3={`${negocioiTem?.url3}`}
          titulourl3={`${negocioiTem?.titulourl3}`}
          url4={`${negocioiTem?.url4}`}
          titulourl4={`${negocioiTem?.titulourl4}`}
          btnFecharModal={`${data.btnFecharModal}`}
        />
      </div>
    </>
  );
};

export default Negocios;
