import React from 'react';
import logo from '../../images/Logo-INPI.png';
import Isviet from '../Isviet';

interface Menu {
  dadosMenu: object;
}

const Menu: React.FC<Menu> = ({ dadosMenu }: Menu) => {
  const a = JSON.stringify(dadosMenu, null, '');
  const data = JSON.parse(a);

  return (
    <>
      <header id="header-1" className="header-1 soft-scroll">
        <nav className="main-nav navbar-fixed-top headroom headroom--pinned">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a href="#header-1">
                <Isviet acao="apontando" />
                <img
                  src={logo}
                  className="brand-img img-responsive"
                  alt="log"
                />
              </a>
            </div>

            <div className="collapse navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                <li className="active nav-item">
                  <a href="#header-1">{data.inicio}</a>
                </li>
                <li className="nav-item">
                  <a href="#historia">{data.historia}</a>
                </li>
                <li className="nav-item">
                  <a href="#noticias">{data.noticia}</a>
                </li>
                <li className="nav-item">
                  <a href="#negocios">{data.negocio}</a>
                </li>
                <li className="nav-item">
                  <a href="#metodologia" target="_tab">
                    {data.metodologia}
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#parceiros">{data.parceiro}</a>
                </li>
                <li className="nav-item">
                  <a href="#contato">{data.contato}</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Menu;
