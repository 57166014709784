import React from 'react';

import logistics from '../../images/RodaBCC2teste.jpg';
import logistic3 from '../../images/dirigivel2.jpg';
import transport from '../../images/shipping.jpg';

interface Header {
  dados: object;
}

const Header: React.FC<Header> = ({ dados }: Header) => {
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);

  return (
    <>
      <div
        id="myCarousel"
        className="carousel slide hidden-xs "
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" className="active" />
          <li data-target="#myCarousel" data-slide-to="1" />
          <li data-target="#myCarousel" data-slide-to="2" />
        </ol>

        <div className="carousel-inner">
          <div className="item active">
            <a href={data.urlimagen1} target="_tab">
            <img src={logistics} alt="Los Angeles" />
            </a>
            <div className="carousel-caption">
              <h3>{data.slogan1}</h3>
              {/**<p>{data.slogan2}</p>*/}
            </div>
          </div>

          <div className="item">
            <a href={data.urlimagen2} target="_tab">
            <img src={transport} alt="Chicago" />
             </a>
            <div className="carousel-caption">
              <h3>{data.slogan3}</h3>
              
            </div>
          </div>

          <div className="item">
            {/** <a href={data.urlimagen3} target="_tab"> */}
            <img src={logistic3} alt="New York" />
            {/** </a> */}
            <div className="carousel-caption">
              {/**<h3>{data.slogan1}</h3>*/}
              <p>{data.slogan2}</p>
            </div>
          </div>
        </div>

        <a
          className="left carousel-control"
          href="#myCarousel"
          data-slide="prev"
        >
          <span className="glyphicon glyphicon-chevron-left" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control"
          href="#myCarousel"
          data-slide="next"
        >
          <span className="glyphicon glyphicon-chevron-right" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </>
  );
};

export default Header;
