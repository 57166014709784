import React from 'react';

interface Props {
  dados: object;
  traducao: string;
}

const Contatos: React.FC<Props> = ({ dados, traducao }: Props) => {
  const a = JSON.stringify(dados, null, '');
  const data = JSON.parse(a);

  return (
    <>
      <section className="content-block contact-1" id="contato">
        <div className="container text-center">
          <div className="col-sm-10 col-sm-offset-1">
            <div className="underlined-title">
              <br />
              <h1>{data.titulo}</h1>
              <hr />
              <h2>{data.subtitulo}</h2>
              <h2>{data.subtitulo2}</h2>
            </div>
            <p>{data.endereco}</p>
            <ul className="contact-info">
              <li>
                <span className="fa fa-phone" />
                <a href={`tel:${data.telefone}`}>{data.telefone}</a>
              </li>
              <li>
                <span className="fa fa-envelope" />
                <a href={`mailto:${data.email}`}>{data.email}</a>
              </li>
            </ul>
            <div id="contact" className="form-container">
              <div id="message" />
              <form
                method="post"
                action="js/contact-form.php"
                name="contactform"
                id="contactform"
              >
                <input type="hidden" id="lang" name="lang" value={traducao} />
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        name="name"
                        id="name"
                        type="text"
                        placeholder={`${data.campo_nome}`}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        name="email"
                        id="email"
                        type="text"
                        defaultValue=""
                        placeholder={`${data.campo_email}`}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        name="phone"
                        id="phone"
                        type="text"
                        defaultValue=""
                        placeholder={`${data.campo_telefone}`}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="comments"
                    id="comments"
                    rows={3}
                    className="form-control"
                    placeholder={`${data.campo_mensagem}`}
                  />
                  <p className="small text-muted">
                    <span className="guardsman">
                      {data.alerta_campo_obrigatorio}
                    </span>
                    {data.mensagem_informativa}
                  </p>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    id="cf-submit"
                    name="submit"
                  >
                    {data.btn_enviar}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contatos;
